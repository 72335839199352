import Link from "next/link"
import Image from "next/legacy/image"
import styles from "./project.module.css"
import Arrow from "@/common/icons/arrow"
import { EnergySaving } from "@/common/icons/energy-saving"

export default function Project({ post }) {
  if (!post) {
    return null
  }

  const {
    type,
    action,
    image,
    title,
    url,
    organization,
    municipality,
    energy
  } = post

  return (
    <div className={styles.card}>
      <Link href={url} prefetch={false} legacyBehavior>
        <a>
          <div className="image-wrapper">
            {energy && (
              <div className="icon">
                <EnergySaving />
              </div>
            )}
            <figure>
              {image && (
                <Image
                  src={image.url}
                  sizes={image.sizes}
                  alt={image.alt}
                  layout="fill"
                  loading="lazy"
                />
              )}
            </figure>
          </div>
          <div className="content">
            {type && <span className="type">{type}</span>}
            <h2>{title}</h2>
            <ul>
              {action.length > 0 && (
                <li>
                  <strong>Åtgärdstyp: </strong>
                  {action.join(", ")}
                </li>
              )}
              {municipality && (
                <li>
                  <strong>Kommun: </strong>
                  {municipality}
                </li>
              )}
              {organization && (
                <li>
                  <strong>Organisation: </strong>
                  {organization}
                </li>
              )}
            </ul>
          </div>
          <footer>
            Mer information
            <div className="square">
              <Arrow />
            </div>
          </footer>
        </a>
      </Link>
    </div>
  )
}
