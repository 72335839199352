import React, { createContext, useContext, useReducer } from "react"

// Create the context
export const ArchiveContext = createContext()
export const useArchiveContext = () => useContext(ArchiveContext)

// Define the initial state function
const createInitialState = (props) => ({
  context: props.context ?? null,
  list: props.list ?? [],
  loading: true,
  page: props.page ?? 1,
  maxPage: props.maxPage ?? 0,
  totalCount: props.totalCount ?? 0,
  view: props.view ?? "grid",
  hash: props.hash ?? null,
  attrs: props.attrs ?? {},
  hasSearched: false
})

// Define the reducer
const archiveReducer = (state, action) => {
  switch (action.type) {
    case "RESET_STATE":
      return createInitialState(action.payload)
    case "SET_LOADING":
      return { ...state, loading: action.loading }
    case "SET_PAGE":
      return { ...state, page: parseInt(action.page, 10) }
    case "SET_LIST":
      return {
        ...state,
        context: action.context,
        list: action.list,
        totalCount: action.totalCount,
        maxPage: action.maxPage,
        hash: action.hash,
        attrs: action.attrs,
        hasSearched: true
      }
    case "SET_VIEW":
      return { ...state, view: action.view }
    case "SET_HASH":
      return { ...state, hash: action.hash }
    default:
      return state
  }
}

// Define the provider component
export const ArchiveProvider = ({ children, ...props }) => {
  const [state, dispatch] = useReducer(
    archiveReducer,
    createInitialState(props)
  )

  // Define getters
  const getContext = () => state.context
  const getHash = () => state.hash
  const getList = () => state.list
  const getLoading = () => state.loading
  const getPage = () => parseInt(state.page, 10)
  const getMaxPage = () => state.maxPage
  const getTotalCount = () => state.totalCount
  const getView = () => state.view
  const getAttrs = () => state.attrs
  const getHasSearched = () => state.hasSearched

  // Define setters
  const resetContext = (props) =>
    dispatch({ type: "RESET_STATE", payload: props })
  const setLoading = (loading) => dispatch({ type: "SET_LOADING", loading })
  const setPage = (page) => dispatch({ type: "SET_PAGE", page })
  const setList = (context, list, totalCount, maxPage, cardType, hash, attrs) =>
    dispatch({
      type: "SET_LIST",
      context,
      list,
      totalCount,
      maxPage,
      cardType,
      hash,
      attrs
    })
  const setView = (view) => dispatch({ type: "SET_VIEW", view })

  return (
    <ArchiveContext.Provider
      value={{
        ...state,
        getContext,
        getHash,
        getList,
        getLoading,
        getPage,
        getMaxPage,
        getTotalCount,
        getView,
        getAttrs,
        getHasSearched,
        resetContext,
        setLoading,
        setPage,
        setList,
        setView
      }}>
      {children}
    </ArchiveContext.Provider>
  )
}
