import { useEffect, useState } from "react"
import { usePostType } from "@/lib/helpers/template"
import {
  usePathData,
  usePropsObject,
  useAdminBar
} from "@/lib/contexts/appContext"
import Main from "@/components/wrappers/main"
import Breadcrumbs from "@/components/base/breadcrumbs/breadcrumbs"
import Blocks from "@/components/Blocks"
import Byline from "@/common/article/byline"
import ArticleCard from "@/common/cards/article/article"
import ProductCard from "@/common/cards/product/product"
import ProjectCard from "@/common/cards/project/project"
import CTA from "@/common/article/cta"
import Terms from "@/common/article/terms"
import Like from "@/common/social/like"
import Share from "@/common/social/share"
import styles from "./article.module.css"
import useMenuItems from "@/lib/helpers/menu"
import classNames from "classnames/bind"
import { pushDataLayer } from "@/lib/helpers/tagManager"

export default function Article() {
  const object = usePropsObject()
  const pathData = usePathData()
  const postType = usePostType()
  const adminBar = useAdminBar()
  const hasTopMenu = !!useMenuItems("top-bramiljoval")
  const cx = classNames.bind(styles)
  const [classes, setClasses] = useState({
    main: true,
    "has-admin-bar": adminBar,
    "has-top-menu": hasTopMenu
  })

  useEffect(() => {
    setClasses({
      ...classes,
      "has-admin-bar": adminBar,
      "has-top-menu": hasTopMenu
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData])

  const primary = object.terms.filter((term) => term.isPrimary === true).shift()
  const crumbs = [{ label: object.title, uri: null }]
  if (postType !== "bmv-project" && primary) {
    crumbs.unshift({ label: primary.label, uri: primary.url })
  }

  if (postType === "bmv-project") {
    object.terms.forEach((crumb) => {
      crumbs.unshift({ label: crumb.label, uri: crumb.url })
    })
  }

  crumbs.unshift({ label: "Startsida", uri: "/" })

  useEffect(() => {
    pushDataLayer(object.tracking)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Main className={cx(classes)}>
      <Breadcrumbs crumbs={crumbs} gap="none" />
      <article className={styles.article}>
        <Blocks blocks={pathData.content} />
      </article>
      <CTA data={object.cta} />
      {postType !== "bmv-project" && ( // Projects should render without this for some reason
        <Terms
          terms={object.terms}
          className="max-w-screen-sm px-4 mt-10 sm:px-10 md:max-w-1xl"
        />
      )}
      {postType !== "bmv-project" && ( // Projects should render without this for some reason
        <div className="box-content items-center justify-between max-w-screen-sm px-4 mx-auto mt-6 mb-6 sm:flex sm:px-10 md:max-w-1xl post-social no-print">
          <Like id={object.id} likes={object.likes} />
          <div className="flex">
            <Share classes="mt-4 sm:mt-0 mr-2 w-icon h-icon" url={object.url} />
          </div>
        </div>
      )}
      {object.byline && <Byline byline={object.byline} />}
      <Related related={object.related} postType={postType} />
    </Main>
  )
}

const Related = ({ related, postType }) => {
  if (!related || related.posts.length === 0) {
    return null
  }

  return (
    <div
      className={classNames(styles["related-wrapper"], "no-print", {
        "is-white": postType === "bmv-project" // Related should render white and not regular light-blue for some reason
      })}>
      <div className="inner-wrapper">
        {related?.label && (
          <h2 className="mb-8 text-xl sm:text-2xl lg:text-3xl">
            {related.label}
          </h2>
        )}
        <div className="list">
          <Cards posts={related.posts} postType={postType} />
        </div>
      </div>
    </div>
  )
}

const Cards = ({ posts, postType }) => {
  return posts.map((post, key) => {
    let Card = ArticleCard

    switch (postType) {
      case "bmv-product":
        Card = ProductCard
        break

      case "bmv-project":
        Card = ProjectCard
        break

      default:
        break
    }

    return <Card post={post} key={key} classes="mb-0" />
  })
}
