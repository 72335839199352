import Link from "next/link"
import Image from "next/image"
import styles from "./product.module.css"
import Arrow from "@/common/icons/arrow"

export default function Product({ post }) {
  if (!post) {
    return null
  }

  const { tag, icon, heading, label, url, licenseDate, licenseHolder } = post

  return (
    <div className={styles.card}>
      <Link href={url} prefetch={false}>
        <div className="inner-wrapper">
          <div className="icon">
            <figure>
              {icon && (
                <Image
                  src={icon}
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center"
                />
              )}
            </figure>
          </div>
          <div className="content">
            {tag && <h6>{tag.label}</h6>}
            <h4>{heading}</h4>
            <div className="text">
              <span>{licenseHolder}</span>
              <span>{licenseDate}</span>
            </div>
          </div>
        </div>
        <footer>
          {label}
          <div className="square">
            <Arrow />
          </div>
        </footer>
      </Link>
    </div>
  )
}
