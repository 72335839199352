import { useEffect, useState } from "react"
import {
  usePathData,
  usePropsObject,
  useAppContext,
  useAdminBar
} from "@/lib/contexts/appContext"
import useMenuItems from "@/lib/helpers/menu"
import Main from "@/components/wrappers/main"
import Blocks from "@/components/Blocks"
import { ArchiveProvider } from "@/lib/contexts/archiveContext"
import { ArchiveProvider as ArchiveProvider2 } from "@/lib/contexts/archiveContext-2"
import styles from "./archive.module.css"
import classNames from "classnames"

export function Archive() {
  const object = usePropsObject()
  const appContext = useAppContext()
  const pathData = usePathData()
  const adminBar = useAdminBar()
  const hasTopMenu = !!useMenuItems("top-bramiljoval")
  const [classes, setClasses] = useState([
    "template-" + pathData.template,
    "text-black-100"
  ])

  useEffect(() => {
    setClasses([
      "template-" + pathData.template,
      "text-black-100",
      adminBar ? "has-admin-bar" : "",
      hasTopMenu ? "has-top-menu" : "",
      object?.bgColor === "green" ? "has-green-bg" : "has-white-bg"
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminBar, hasTopMenu, object?.bgColor, pathData.template])

  const { list, context, maxPage, totalCount, cardType, attrs } = object.archive

  if (["projects", "site"].includes(context)) {
    return (
      <Main className={classNames(classes, styles.main)}>
        <ArchiveProvider2 {...object.archive}>
          <Blocks blocks={pathData.content} />
        </ArchiveProvider2>
      </Main>
    )
  }

  return (
    <Main className={classNames(classes, styles.main)}>
      <ArchiveProvider
        object={object}
        context={context}
        list={list}
        maxPage={maxPage}
        totalCount={totalCount}
        cardType={cardType}
        page={object.page}
        phrase={appContext.phrase}
        attrs={attrs}
        selected={appContext.selected}>
        <Blocks blocks={pathData.content} />
      </ArchiveProvider>
    </Main>
  )
}

export default Archive
