import Image from "next/legacy/image"
import { useAppContext } from "@/lib/contexts/appContext"
import Button from "@/common/button/button"
import styles from "./cta.module.css"
import classNames from "classnames"

export default function CTA({ data, inserted = false }) {
  const { mainCTA } = useAppContext()

  if (!data) {
    return <div ref={mainCTA}></div>
  }

  const { theme } = data

  const noImage = !data.image ? "sm:py-10" : ""
  const bgColor = theme?.bgcolor
    ? "theme-" + theme?.bgcolor
    : "bg-good-green-10"
  const txtColor = theme?.txtcolor === "white" ? "text-white" : null
  const outline = theme?.outline ? "outlined" : null

  return (
    <div
      ref={mainCTA}
      className={classNames([
        styles.wrapper,
        "no-print",
        { "is-sticky": data?.sticky, inserted: inserted }
      ])}>
      <div
        className={classNames([
          "flex",
          styles.themes,
          bgColor,
          txtColor,
          outline
        ])}>
        {data.image && (
          <div className="image-wrapper">
            <div className="inner-wrapper">
              <Image
                className="object-cover object-center w-full h-full"
                src={data.image.url}
                sizes={data.image.sizes}
                alt={data.image.alt}
                layout="fill"
                loading="lazy"
              />
            </div>
          </div>
        )}
        <div className="text-wrapper">
          <div className={"px-6 sm:px-10" + noImage}>
            <h2>{data.title}</h2>
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: data.text }}></div>
            {data.url && (
              <Button
                tagName="a"
                theme={data?.theme?.button ?? "good-green"}
                href={data.url}
                target={data.target}
                className="inline-block">
                {data.label}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
