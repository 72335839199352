import classNames from "classnames"

export function EnergySaving({ className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      className={classNames(className)}>
      <g fill="#00852E">
        <path d="M4 13H0v11h4V13Zm15 6h-7l-2.09-.73.33-.94L12 18h2.82c.65 0 1.18-.53 1.18-1.18 0-.49-.31-.93-.77-1.11L7.97 13H6v9.02L13 24l8.01-3c-.01-1.1-.9-2-2.01-2Z" />
        <path d="M8 8c0-4.417 3.583-8 8-8s8 3.583 8 8a8.004 8.004 0 0 1-7.006 7.939 1.2 1.2 0 0 0-.764-1.229l-7.131-2.662A7.96 7.96 0 0 1 8 8Zm7.583 1.395v5.018l3.904-7.808h-2.685V1.587l-4.008 7.808h2.79Z" />
      </g>
    </svg>
  )
}
